import { createSlice } from '@reduxjs/toolkit'
import uuid from "react-uuid";
import {ORDER_LONG} from "../Constants";


const newDefaultBot =  () => {return {  botId: uuid(),
                                           heartbeat: Date.now(),
                                           isRunning: false,
                                           clientId: "",
                                           config: {
                                               units: '15000',
                                               startPricePoint: '1.06000',
                                               startByMarket: false,
                                               orderType: ORDER_LONG,
                                               instrument: "EUR_USD",
                                               stepPips: '10',
                                               upOrders: '2',
                                               downOrders: '2'
                                           },
                                           trackingOrders: [],
                                           profitCount: 0,
                                           profit: 0,
                                           orderSequencer: 0, } }
const defaultSettings = {bots: [newDefaultBot()]}

export const botStateSlicer = createSlice({
    name: 'botState',
    initialState: {
        value: defaultSettings
    },
    reducers: {
        setBotsFromDB: (state, action ) => {
            state.value = {...state.value, ...action.payload};
        },
        addNewBot: (state) => {
            if (state.value.bots.length > 1) return;

            state.value = {...state.value,
                            bots: [...state.value.bots, newDefaultBot()]};
        },
        setBotTrackingOrders: (state, action) => {
            let botIndex = action.payload.index;
            let config = action.payload.trackingOrders;
            let bots = [...state.value.bots];
            bots[botIndex] = {...bots[botIndex], config: config};
            state.value = {...state.value, bots: [...bots]}
        },
        setBotProps: (state, action) => {
            let botIndex = action.payload.index;
            let props = action.payload.props;
            let bots = [...state.value.bots];
            bots[botIndex] = {...bots[botIndex], ...props};
            state.value = {...state.value, bots: [...bots]}
        },
        setBotConfig: (state, action) => {
            let botIndex = action.payload.index;
            let config = action.payload.config;
            let bots = [...state.value.bots];
            bots[botIndex] = {...bots[botIndex], config: config};
            state.value = {...state.value, bots: [...bots]}
        },
        // {index: , orders}
        setTrackingOrders: (state, action) => {
            let botIndex = action.payload.index;
            let orders = [...action.payload.orders];
            let bots = [...state.value.bots];
            bots[botIndex] = {...bots[botIndex], trackingOrders: orders};
            state.value = {...state.value, bots: [...bots]}
        },
        increaseProfitAndCount: (state, action) => {
            let botIndex = action.payload.id;
            let profit = Number(action.payload.profit);
            let bots = [...state.value.bots];
            let storedProfit = bots[botIndex].profit ? bots[botIndex].profit : 0;
            let profitCount = bots[botIndex].profitCount ? bots[botIndex].profitCount : 0;
            bots[botIndex] = {...bots[botIndex], profitCount: profitCount + 1, profit: storedProfit + profit};
            state.value = {...state.value, bots: [...bots]}
        },
        increaseOrderSequence: (state, action) => {
            let botIndex = action.payload;
            let bots = [...state.value.bots];
            let orderSequencer = bots[botIndex].orderSequencer ? bots[botIndex].orderSequencer : 0;
            bots[botIndex] = {...bots[botIndex], orderSequencer: orderSequencer + 1};
            state.value = {...state.value, bots: [...bots]}
        },
        resetOrderSequence: (state, action) => {
            let botIndex = action.payload;
            let bots = [...state.value.bots];
            bots[botIndex] = {...bots[botIndex], orderSequencer: 0};
            state.value = {...state.value, bots: [...bots]}
        },
        resetProfitCount: (state, action) => {
            let botIndex = action.payload;
            let bots = [...state.value.bots];
            bots[botIndex] = {...bots[botIndex], profitCount: 0};
            state.value = {...state.value, bots: [...bots]}
        },
        heartbeat: (state, action) => {
            let botIndex = action.payload;
            let bots = [...state.value.bots];
            bots[botIndex] = {...bots[botIndex], heartbeat: Date.now()};
            state.value = {...state.value, bots: [...bots]};
        }
    }
})

// Action creators are generated for each case reducer function
export const { increaseProfitAndCount, resetProfitCount, heartbeat, addNewBot, setBotsFromDB, setBotConfig, setBotProps, increaseOrderSequence, resetOrderSequence, increaseProfit } = botStateSlicer.actions
export const selectBots = (state) => state.botState.value.bots
export default botStateSlicer.reducer