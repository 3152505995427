import { createSlice } from '@reduxjs/toolkit'
import uuid from "react-uuid";
import {ORDER_LONG} from "../Constants";


const newDefaultBot = () => {
    return {
        config: {
            apiKey: "",
            url: "",
            apiType: "NONE",
            units: '5000',
            startPricePoint: '1.06000',
            longAccount: "",
            shortAccount: "",
            instrument: "",
            stepPips: '30',
        },
        stats: {
            cs: 0, //closed short
            cl: 0, //closed short
            os: 0, //open sort
            ol: 0, //open long
            ws: 0, //waiting short
            wl: 0  //waiting long
        },
        heartbeat: 0,
        run: false
    }
};

const defaultSettings = { bot1: newDefaultBot(),
                          bot2: newDefaultBot()
}

export const snakeBotStateSlicer = createSlice({
    name: 'snakeBotState',
    initialState: {
        value: defaultSettings
    },
    reducers: {
        setSnakeBotConfig: (state, action ) => {
            let botId = action.payload.index;
            let config = action.payload.config;
            if (config === undefined || config === null) return;
            let botState = state.value[botId];
            botState.config = config;
            state.value[botId] = {...botState};
        },
        setSnakeBotStats: (state, action ) => {
            let botId = action.payload.index;
            let stats = action.payload.stats;
            if (stats === undefined || stats === null) return;

            let botState = state.value[botId];
            botState.stats = stats;
            state.value[botId] = {...botState};
        },
        setSnakeBotHeartbeat: (state, action ) => {
            let botId = action.payload.index;
            let heartbeat = action.payload.heartbeat;
            if (heartbeat === undefined || heartbeat === null) return;

            let botState = state.value[botId];
            botState.heartbeat = heartbeat;
            state.value[botId] = {...botState};
        },
        setSnakeBotRunState: (state, action ) => {
            let botId = action.payload.index;
            let run = action.payload.run;
            if (run === undefined || run === null) return;

            let botState = state.value[botId];
            botState.run = run;
            state.value[botId] = {...botState};
        }
    }
})

// Action creators are generated for each case reducer function
export const { setSnakeBotConfig, setSnakeBotRunState, setSnakeBotStats, setSnakeBotHeartbeat} = snakeBotStateSlicer.actions
export const selectSnakeBotState = (state) => state.snakeBotState.value
export default snakeBotStateSlicer.reducer