import { createSlice } from '@reduxjs/toolkit'
import uuid from "react-uuid";
import {ORDER_LONG} from "../Constants";


const newDefaultBot = () => {
    return {
        config: {
            apiKey: "",
            url: "",
            apiType: "NONE",
            units: '5000',
            startPricePoint: '1.06000',
            orderType: 'LONG',
            instrument: "EUR_USD",
            stepPips: '30',
            upOrders: '2',
            downOrders: '2'
        },
        stats: {
            c: 0, //closed
            o: 0, //open
            p: 0, //profit
            w: 0,  //waiting
            un: 0, //unrealized profit/loss
        },
        prevRunStats: {},
        heartbeat: 0,
        run: false
    }
};

const defaultSettings = { bot1: newDefaultBot(),
                          bot2: newDefaultBot(),
                          bot3: newDefaultBot(),
                          bot4: newDefaultBot(),
                          bot5: newDefaultBot(),
                          bot6: newDefaultBot()
}

export const serverBotStateSlicer = createSlice({
    name: 'serverBotState',
    initialState: {
        value: defaultSettings
    },
    reducers: {
        setServerBotConfig: (state, action ) => {
            let botId = action.payload.index;
            let config = action.payload.config;
            if (config === undefined || config === null) return;
            let botState = state.value[botId];
            botState.config = config;
            state.value[botId] = {...botState};
        },
        setServerBotStats: (state, action ) => {
            let botId = action.payload.index;
            let stats = action.payload.stats;
            if (stats === undefined || stats === null) return;

            let botState = state.value[botId];
            botState.stats = stats;
            state.value[botId] = {...botState};
        },
        setServerBotHeartbeat: (state, action ) => {
            let botId = action.payload.index;
            let heartbeat = action.payload.heartbeat;
            if (heartbeat === undefined || heartbeat === null) return;

            let botState = state.value[botId];
            botState.heartbeat = heartbeat;
            state.value[botId] = {...botState};
        },
        setServerBotRunState: (state, action ) => {
            let botId = action.payload.index;
            let run = action.payload.run;
            if (run === undefined || run === null) return;

            let botState = state.value[botId];
            botState.run = run;
            state.value[botId] = {...botState};
        },
        setServerBotprevRunStats: (state, action ) => {
            let botId = action.payload.index;
            let prevRunStats = action.payload.prevRunStats;
            if (prevRunStats === undefined || prevRunStats === null) return;

            let botState = state.value[botId];
            botState.prevRunStats = prevRunStats;
            state.value[botId] = {...botState};
        }
    }
})

// Action creators are generated for each case reducer function
export const { setServerBotConfig, setServerBotprevRunStats, setServerBotRunState, setServerBotStats, setServerBotHeartbeat} = serverBotStateSlicer.actions
export const selectServerBotState = (state) => state.serverBotState.value
export default serverBotStateSlicer.reducer