// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut} from "firebase/auth";
import store from './store'
import {isLoggedIn, setDevice, setUser, updateDeviceHeartbeat} from "./login/loginSlicer";
import {getDatabase} from "firebase/database";
import {
    getUserSettings,
    listenOnBotsChange, listenOnLuckyBotChange, listenOnLuckyStrategyChange,
    listenOnServerBotChange, listenOnSnakeBotChange
} from "./datebase/db";
import {setUserSetting, setUserSettingsDefault} from "./user/userSettingsSlicer";
import {setBotsFromDB} from "./bot/botStateSclicer";
import getBrowserFingerprint from 'get-browser-fingerprint';
const { platform, userAgent, vendor} = window.navigator;

const fingerprint = getBrowserFingerprint();
console.log(fingerprint);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAyimZK2CV0q5O_SjZcBpwFw6p99tfK7x4",
    authDomain: "octopusoandatraider.firebaseapp.com",
    projectId: "octopusoandatraider",
    storageBucket: "octopusoandatraider.appspot.com",
    messagingSenderId: "187185008194",
    appId: "1:187185008194:web:05b3d94d353806a8bf67db",
    measurementId: "G-8FY46B8HRB",
    databaseURL: "https://octopusoandatraider-default-rtdb.firebaseio.com/",
};

// Initialize Realtime Database and get a reference to the service

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();
const database = getDatabase(app);


const auth = getAuth();
export const logIn = () => {
    // signInWithRedirect(auth, provider);
    signInWithPopup(auth, provider);
};
export const logOut = () => {
    signOut(auth)
};

let deviceHeartbeatTimerId = 0;

const deviceHeartbeatTimer = () => {
    store.dispatch(updateDeviceHeartbeat());
    deviceHeartbeatTimerId = setTimeout(() => deviceHeartbeatTimer(), 10000);
}

const SNAKE_BOT_PATH = "snakeBot";
const OCTOPUS_BOT_PATH = "serverBots";
const LUCKY_BOT_PATH = "luckyBot";
const LUCKY_BOT_STRATEGY_PATH = "luckyBotStrategy";

const BOT_CONFIGS = [
    {
        id: "octopus",
        name: "Octopus",
        prefix: "bot",
        path: OCTOPUS_BOT_PATH,
        enabled: true,
        botsNumber: 7
    },
    {
        id: "snake",
        name: "Snake",
        prefix: "bot",
        path: SNAKE_BOT_PATH,
        enabled: true,
        botsNumber: 3
    },{
        id: "lucky",
        name: "Lucky",
        prefix: "bot",
        path: LUCKY_BOT_PATH,
        enabled: true,
        botsNumber: 3
    },
    {
        id: "luckyStrategy",
        name: "LuckyStrategy",
        prefix: "strategy",
        path: LUCKY_BOT_STRATEGY_PATH,
        enabled: true,
        botsNumber: 3
    }
];

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        store.dispatch(isLoggedIn(true));
        store.dispatch(setUser({uid: user.uid, displayName: user.displayName, email: user.email}));
        store.dispatch(setDevice({id: fingerprint, platform: platform, userAgent: userAgent, vendor: vendor}));
        getUserSettings(user.uid, (settings) => store.dispatch(setUserSetting(settings)));
        listenOnBotsChange(user.uid);
        for (let configEntity of BOT_CONFIGS) {
            for (let i = 1; i < configEntity.botsNumber; i ++)
            {
                if (configEntity.id === "octopus") {
                    listenOnServerBotChange(configEntity, user.uid, configEntity.prefix + i);
                }
                if (configEntity.id === "snake") {
                    listenOnSnakeBotChange(configEntity, user.uid, configEntity.prefix + i);
                }
                if (configEntity.id === "lucky") {
                    listenOnLuckyBotChange(configEntity, user.uid, configEntity.prefix + i);
                }
                if (configEntity.id === "luckyStrategy") {
                    listenOnLuckyStrategyChange(configEntity, user.uid, configEntity.prefix + i);
                }
            }
        }
        // getUserBots(user.uid, (bots) => store.dispatch(setBotsFromDB(bots)));

    } else {
        // User is signed out
        // ...
        store.dispatch(isLoggedIn(false));
        store.dispatch(setUser({}));
        store.dispatch(setUserSettingsDefault());
        clearTimeout(deviceHeartbeatTimerId);
    }
});