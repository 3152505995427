import { createSlice } from '@reduxjs/toolkit'


const newDefaultBot = () => {
    return {
        config: {
            apiKey: "",
            url: "",
            templateId: "",
            apiType: "NONE",
            units: '1000',
            instrument: "USD_CAD",
            stopLossPips: '0',
            takeProfitPips: '2'
        },
        stats: {
            c: 0, //closed
            o: 0, //open
            w: 0  //waiting
        },
        heartbeat: 0,
        run: false
    }
};

const defaultSettings = { bot1: newDefaultBot(),
                          bot2: newDefaultBot(),
                          bot3: newDefaultBot(),
                          bot4: newDefaultBot(),
                          bot5: newDefaultBot(),
                          bot6: newDefaultBot()
}

export const luckyBotStateSlicer = createSlice({
    name: 'luckyBotState',
    initialState: {
        value: defaultSettings
    },
    reducers: {
        setLuckyBotConfig: (state, action ) => {
            let botId = action.payload.index;
            let config = action.payload.config;
            if (config === undefined || config === null) return;
            let botState = state.value[botId];
            botState.config = config;
            state.value[botId] = {...botState};
        },
        setLuckyBotStats: (state, action ) => {
            let botId = action.payload.index;
            let stats = action.payload.stats;
            if (stats === undefined || stats === null) return;

            let botState = state.value[botId];
            botState.stats = stats;
            state.value[botId] = {...botState};
        },
        setLuckyBotHeartbeat: (state, action ) => {
            let botId = action.payload.index;
            let heartbeat = action.payload.heartbeat;
            if (heartbeat === undefined || heartbeat === null) return;

            let botState = state.value[botId];
            botState.heartbeat = heartbeat;
            state.value[botId] = {...botState};
        },
        setLuckyBotRunState: (state, action ) => {
            let botId = action.payload.index;
            let run = action.payload.run;
            if (run === undefined || run === null) return;

            let botState = state.value[botId];
            botState.run = run;
            state.value[botId] = {...botState};
        }
    }
})

// Action creators are generated for each case reducer function
export const { setLuckyBotConfig, setLuckyBotRunState, setLuckyBotStats, setLuckyBotHeartbeat} = luckyBotStateSlicer.actions
export const selectLuckyBotState = (state) => state.luckyBotState.value
export default luckyBotStateSlicer.reducer