import React from 'react';
import {Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import {fetchData} from "../requests";

function AccountsPicker({apiKey, url, accounts, enableLoad, onLoad, onChange, accountIn, altTitle}) {
    // const apiKey = useSelector(selectUserSettingsApiKey);
    // const url = useSelector(selectUserSettingsUrl);
    // const accounts = useSelector(selectUserSettingsAccounts);

    const handleAccountChange = (event) => {
        let accountId = event.target.value;
        if (onChange) {
            onChange(accountId)
        }
    };

    async function accountsDetails(accounts) {
        let accs = []
        for (let account of accounts) {
            let acc = await fetchData(url, apiKey, account.id, "summary");
            if (acc && acc.account) {
                accs.push(acc.account);
            }
            if (acc && acc.errorMessage) {
                accs.push({id: account.id, alias: "", balance: ""});
            }
        }
        return accs;
    }

    async function getAccounts() {
        let data = await fetchData(url, apiKey);
        if (data.accounts) {
            let accounts = await accountsDetails(data.accounts);
            if (onLoad) {
                onLoad(accounts);
            }
        } else {
            if (onLoad) {
                onLoad([]);
            }
        }
    }


    return (
        <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                {!enableLoad &&
                    <>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2"> {altTitle ? altTitle : "Account"} </InputGroup.Text>
                            <Form.Select aria-label="Default select example" aria-describedby="basic-addon2"
                                         value={accountIn}
                                         onChange={handleAccountChange}>
                                <option>Choose account</option>
                                {accounts?.map((item) => (
                                    <option key={item.id}
                                            value={item.id}>{item.alias}: {item.id} ({item.balance})</option>
                                ))
                                }
                            </Form.Select>
                        </InputGroup>
                        {enableLoad &&
                            <Col>
                                <Button onClick={() => getAccounts()}>Refresh</Button>
                            </Col>
                        }
                    </>
                }
                {enableLoad &&
                    <>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Accounts <Button onClick={() => getAccounts()}>Refresh</Button></th>
                                <th>Alias</th>
                                <th>Balance</th>
                            </tr>
                            </thead>
                            <tbody>
                            {accounts?.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.alias}</td>
                                    <td>{item.balance}</td>
                                </tr>
                            ))
                            }
                            </tbody>
                        </Table>
                    </>
                }
            </Form.Group>
        </>
    );
}

export default AccountsPicker;
