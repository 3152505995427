import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, {useState} from 'react';
import {ORDER_LONG, ORDER_SHORT, pip} from "../Constants";
import '../BotOctopus.css';
import {Alert, Button, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    saveServerBotsConfigAsync,
    saveServerBotsRunAsync,
    saveUserBotsAsync
} from "../datebase/db";
import {BsCurrencyDollar, BsFillPlayFill, BsFillStopFill, BsReplyFill} from "react-icons/bs";
import {selectLoginSessionUuid} from "../login/loginSlicer";
import {selectServerBotState, setServerBotConfig, setServerBotRunState} from "./serverBotStateSclicer";
import {
    selectUserSettingsDemoAccounts,
    selectUserSettingsDemoApiKey, selectUserSettingsDemoUrl, selectUserSettingsLiveAccounts,
    selectUserSettingsLiveApiKey,
    selectUserSettingsLiveUrl
} from "../user/userSettingsSlicer";
import AccountsPicker from "../user/AccountsPicker";
import RangeSlider from "react-bootstrap-range-slider";
import {getPricing} from "../requests";

var sequencer = 0;
function BotOctopusServer({botId}) {

    const dispatcher = useDispatch();
    const isRunning = useSelector(selectServerBotState)[botId].run;
    const botHeartbeat = useSelector(selectServerBotState)[botId].heartbeat;
    const botConfig = useSelector(selectServerBotState)[botId].config;
    const account = useSelector(selectServerBotState)[botId].config.account;
    const liveKey = useSelector(selectUserSettingsLiveApiKey);
    const demoKey = useSelector(selectUserSettingsDemoApiKey);
    const liveUrl = useSelector(selectUserSettingsLiveUrl);
    const demoUrl = useSelector(selectUserSettingsDemoUrl);
    const liveAccounts = useSelector(selectUserSettingsLiveAccounts);
    const demoAccounts = useSelector(selectUserSettingsDemoAccounts);
    const apiType = botConfig.apiType;
    const accounts = botConfig.apiType === "NONE" ? [] : botConfig.apiType === "LIVE" ? liveAccounts : demoAccounts;
    const clientId = useSelector(selectLoginSessionUuid);



    const saveConfig = () => {
        // dispatcher(setServerBotRunState({index: botId, run: !isRunning}))
        saveServerBotsConfigAsync(botId);
    }
    const startBot = () => {
        saveServerBotsConfigAsync(botId);
        dispatcher(setServerBotRunState({index: botId, run: !isRunning}))
        saveServerBotsRunAsync(botId);
    }
    const saveBotConfig = (config) => {
        console.log("Bot id " + botId);
        console.log("config", config);
        dispatcher(setServerBotConfig({index: botId, config: {...botConfig, ...config}}));
        saveUserBotsAsync();
    }
    const handleApiChange = (event) => {
        const value = event.target.value;
        const keys = {NONE: {apiType: "NONE", apiKey: "", url: ""},
                      LIVE: {apiType: "LIVE", apiKey: liveKey, url: liveUrl},
                      DEMO: {apiType: "DEMO", apiKey: demoKey, url: demoUrl}}
        saveBotConfig( keys[value] );
    };

    const handleOrderTypeChange = (event) => {
        const value = event.target.value;
        saveBotConfig( { orderType: value});
    };

     const setCurrentMarketPrice = async (event) => {
        const price = await getPricing(botConfig.url, botConfig.apiKey, botConfig.account, botConfig.instrument);
        saveBotConfig( { startPricePoint: price});
    };
    const handleOrderPriceChange = (event) => {
        const value = event.target.value;
        saveBotConfig( { startPricePoint: value});
    };
    const handleInstrumentChange = (event) => {
        const value = event.target.value;
        saveBotConfig( { instrument: value});
    };

    return (
        <Container>
            {!isRunning &&
                <>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Default API Key</InputGroup.Text>
                        <Form.Select aria-label=">Default API Key" value={apiType} onChange={handleApiChange}>
                            <option value={"NONE"}>NONE</option>
                            <option value={"DEMO"}>Demo Api</option>
                            <option value={"LIVE"}>Live Api</option>
                        </Form.Select>
                    </InputGroup>
                    <AccountsPicker accounts={accounts} accountIn={account} onChange={(acc) => saveBotConfig( {account: acc})}/>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">Order type</InputGroup.Text>
                        <Form.Select size={"sm"} aria-label="Default select example"
                                     aria-describedby="basic-addon3" value={botConfig.orderType}
                                     onChange={handleOrderTypeChange}>
                            <option value={"LONG"}>LONG</option>
                            <option value={"SHORT"}>SHORT</option>
                        </Form.Select>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">Instrument</InputGroup.Text>
                        <Form.Select size={"sm"} aria-label="Default select example"
                                     aria-describedby="basic-addon3" value={botConfig.instrument}
                                     onChange={handleInstrumentChange}>
                            <option value={"EUR_USD"}>EUR_USD</option>
                            <option value={"USD_CAD"}>USD_CAD</option>
                            <option value={"EUR_CAD"}>EUR_CAD</option>
                        </Form.Select>
                    </InputGroup>
                    <Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Units to per order</Form.Label>
                            <Container fluid>
                                <RangeSlider value={botConfig.units}
                                             min={1000}
                                             step={1000}
                                             max={50000}
                                             size={"lg"}
                                             onChange={(e) => saveBotConfig({units: e.target.value})}/>
                            </Container>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Start price</Form.Label>
                            <InputGroup>

                                <Form.Control
                                    id={"inputPrice"}
                                    name={"startPricePoint"}
                                    value={botConfig.startPricePoint}
                                    aria-label="Example text with button addon"
                                    aria-describedby="basic-addon2"
                                    onChange={handleOrderPriceChange}
                                />
                                <Button variant="primary" id="button-addon2"
                                        onClick={() => setCurrentMarketPrice()}>
                                    <BsCurrencyDollar/><BsReplyFill/>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Pips step</Form.Label>
                            <Container fluid>
                                <RangeSlider value={botConfig.stepPips}
                                             min={2}
                                             step={1}
                                             max={100}
                                             size={"lg"}
                                             onChange={(e) => saveBotConfig({stepPips: e.target.value})}/>
                            </Container>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Steps up/down</Form.Label>
                            <Container fluid>
                                <RangeSlider value={botConfig.upOrders}
                                             min={1}
                                             step={1}
                                             max={10}
                                             size={"lg"}
                                             onChange={(e) => saveBotConfig({
                                                 upOrders: e.target.value,
                                                 downOrders: e.target.value
                                             })}/>
                            </Container>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Auto Stop when Profit(Relaized - Unrelized)
                                <br/> 0 - don't stop
                                <br/> 1...1000 - Stop and close all trades </Form.Label>
                            <Container fluid>
                                <RangeSlider value={botConfig.autoStopDollarAmount}
                                             min={0}
                                             step={1}
                                             max={1000}
                                             size={"lg"}
                                             onChange={(e) => saveBotConfig({autoStopDollarAmount: e.target.value})}/>
                            </Container>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Button size={"sm"} style={{marginTop: 10, marginBottom: 10}} variant={"success"}
                                onClick={() => saveConfig()}>Save</Button>
                    </Row>
                </>
            }
            <Row>
                <Button size={"sm"} style={{marginTop: 10, marginBottom: 10}} variant={isRunning ? "danger" : "primary"}
                        onClick={() => startBot()}>{isRunning ? <BsFillStopFill/> :
                    <BsFillPlayFill/>}</Button>
            </Row>

        </Container>
    );
}

export default BotOctopusServer;
