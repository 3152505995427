import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import OrdersAndTradesView from "./OrdersAndTradesView";
import Login from "./login/Login";
import {useSelector} from "react-redux";
import UserSettings from "./user/UserSettings";
import {selectLoginIsLoggedIn} from "./login/loginSlicer";
import {Container, Row} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import ServerBotsView from "./serverBot/ServerBotsView";
import BotEditor from "./editor/BotEditor";
import LuckyBotsView from "./luckyBot/LuckyBotsView";
import LuckyStrategyView from "./luckyStrategy/LuckyStrategyView";

function HomePage() {
    const isLoggedIn = useSelector(selectLoginIsLoggedIn);

    return (
        <Container fluid>
            <Row>
                <Login/>
            </Row>
            {isLoggedIn &&
                <Accordion defaultActiveKey={['4']} alwaysOpen>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Settings</Accordion.Header>
                        <Accordion.Body>
                            <UserSettings/>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/*<Accordion.Item eventKey="1">*/}
                    {/*    <Accordion.Header>Bot</Accordion.Header>*/}
                    {/*    <Accordion.Body>*/}
                    {/*        /!*<BotOctopus/>*!/*/}
                    {/*    </Accordion.Body>*/}
                    {/*</Accordion.Item>*/}
                    {/*<Accordion.Item eventKey="3">*/}
                    {/*    <Accordion.Header>Bots</Accordion.Header>*/}
                    {/*    <Accordion.Body>*/}
                    {/*        <BotsView/>*/}
                    {/*    </Accordion.Body>*/}
                    {/*</Accordion.Item>*/}
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Octopus Bot</Accordion.Header>
                        <Accordion.Body>
                            <ServerBotsView/>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/*<Accordion.Item eventKey="5">*/}
                    {/*    <Accordion.Header>Lucky Bot</Accordion.Header>*/}
                    {/*    <Accordion.Body>*/}
                    {/*        <LuckyBotsView/>*/}
                    {/*    </Accordion.Body>*/}
                    {/*</Accordion.Item>*/}
                    {/*<Accordion.Item eventKey="5">*/}
                    {/*    <Accordion.Header>Snake Bot</Accordion.Header>*/}
                    {/*    <Accordion.Body>*/}
                    {/*        <SnakeBotsView/>*/}
                    {/*    </Accordion.Body>*/}
                    {/*</Accordion.Item>*/}
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Orders and trades</Accordion.Header>
                        <Accordion.Body>
                            <OrdersAndTradesView/>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/*<Accordion.Item eventKey="7">*/}
                    {/*    <Accordion.Header>Lucky Strategies</Accordion.Header>*/}
                    {/*    <Accordion.Body>*/}
                    {/*        <LuckyStrategyView/>*/}
                    {/*    </Accordion.Body>*/}
                    {/*</Accordion.Item>*/}
                    {/*<Accordion.Item eventKey="8">*/}
                    {/*    <Accordion.Header>Bot Editor</Accordion.Header>*/}
                    {/*    <Accordion.Body>*/}
                    {/*        <BotEditor/>*/}
                    {/*    </Accordion.Body>*/}
                    {/*</Accordion.Item>*/}
                </Accordion>
            }
        </Container>
    );
}

export default HomePage;
