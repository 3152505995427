import { createSlice } from '@reduxjs/toolkit'

const defaultSettings = {
                         apiKey: "",
                         url: "api-fxpractice.oanda.com",
                         accounts: [],
                         demoApiKey: "",
                         liveApiKey: "",
                         heartbeat: Date.now(),
                         demoUrl: "api-fxpractice.oanda.com",
                         liveUrl: "api-fxtrade.oanda.com",
                         demoAccounts: [],
                         liveAccounts: []
                         }
export const userSettingsSlicer = createSlice({
    name: 'userSettings',
    initialState: {
        value: defaultSettings
    },
    reducers: {
        //this is default key
        setUserSettingsApiKey: (state, action) => {
            state.value = {...state.value, apiKey: action.payload}
        },
        setUserSettingsDemoApiKey: (state, action) => {
            state.value = {...state.value, demoApiKey: action.payload}
        },
        setUserSettingsLiveApiKey: (state, action) => {
            state.value = {...state.value, liveApiKey: action.payload}
        },
        setUserSetting: (state, action) => {
            if (action.payload === undefined) return;
            state.value = {...state.value, ...action.payload}
        },
        //used when loging out
        setUserSettingsDefault: (state) => {
            state.value = {...defaultSettings};
        },
        setUserSettingsUrl: (state, action) => {
            state.value = {...state.value, url: action.payload}
        },
        setUserSettingsDemoAccounts: (state, action) => {
            state.value = {...state.value, demoAccounts: action.payload}
        },
        setUserSettingsLiveAccounts: (state, action) => {
            state.value = {...state.value, liveAccounts: action.payload}
        },
        setUserSettingsAccounts: (state, action) => {
            state.value = {...state.value, accounts: action.payload}
        },
        heartbeat: (state) => {
            state.value = {...state.value, heartbeat: Date.now()};
        }
    }
})

// Action creators are generated for each case reducer function
export const { setUserSettingsApiKey, setUserSettingsAccounts, setUserSettingsLiveApiKey, setUserSettingsDemoApiKey, setUserSetting, setUserSettingsDefault, setUserSettingsUrl, setUserSettingsLiveAccounts, setUserSettingsDemoAccounts } = userSettingsSlicer.actions
export const selectUserSettingsApiKey = (state) => state.userSettings.value.apiKey
export const selectUserSettingsDemoApiKey = (state) => state.userSettings.value.demoApiKey
export const selectUserSettingsLiveApiKey = (state) => state.userSettings.value.liveApiKey
export const selectUserSettingsUrl = (state) => state.userSettings.value.demoUrl
export const selectUserSettingsAccounts = (state) => state.userSettings.value.accounts
export const selectUserSettingsDemoAccounts = (state) => state.userSettings.value.demoAccounts
export const selectUserSettingsDemoUrl = (state) => "api-fxpractice.oanda.com"
export const selectUserSettingsLiveUrl = (state) => "api-fxtrade.oanda.com"
export const selectUserSettingsLiveAccounts = (state) => state.userSettings.value.liveAccounts
export const selectUserSettings = (state) => state.userSettings.value
export default userSettingsSlicer.reducer