import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, {useEffect, useState} from 'react';
import {createOrderRequest, cancelOrderRequset, fetchData, closeTradeRequset} from "./requests";
import Moment from 'moment';

import {Button, Container, Form, InputGroup, Table} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {useSelector} from "react-redux";
import {
    selectUserSettingsAccounts,
    selectUserSettingsApiKey, selectUserSettingsDemoAccounts, selectUserSettingsDemoApiKey, selectUserSettingsDemoUrl,
    selectUserSettingsLiveAccounts, selectUserSettingsLiveApiKey, selectUserSettingsLiveUrl,
    selectUserSettingsUrl
} from "./user/userSettingsSlicer";
import AccountsPicker from "./user/AccountsPicker";

function OrdersAndTradesView() {
    const [orders, setOrders] = useState([]);
    const [trades, setTrades] = useState([]);
    const [account, setAccount] = useState([]);
    const [apiType, setApiType] = useState("NONE");
    const [accounts, setAccounts] = useState([]);
    const [api, setApi] = useState({});
    const url = useSelector(selectUserSettingsUrl);
    const liveAccounts = useSelector(selectUserSettingsLiveAccounts);
    const demoAccounts = useSelector(selectUserSettingsDemoAccounts);
    const liveKey = useSelector(selectUserSettingsLiveApiKey);
    const demoKey = useSelector(selectUserSettingsDemoApiKey);
    const liveUrl = useSelector(selectUserSettingsLiveUrl);
    const demoUrl = useSelector(selectUserSettingsDemoUrl);


    function componentDidMount() {
        setTimeout( ()=> {
            refresh();
        },3000 );
    }

    const handleApiChange = (event) => {
        const value = event.target.value;
        setApiType(value);
        setAccounts(value === "NONE" ? [] : value === "LIVE" ? liveAccounts : demoAccounts);
        const keys = {NONE: {apiType: "NONE", apiKey: "", url: ""},
            LIVE: {apiType: "LIVE", apiKey: liveKey, url: liveUrl},
            DEMO: {apiType: "DEMO", apiKey: demoKey, url: demoUrl}}
        setApi(keys[value]);
    };
    function refresh(account) {
        setAccount(account);
        try {
            getTrades(account);
            getOrders(account);
        }
        catch (error) {
            console.error(" can't get trades or orders", error)
        }
    }

    async function getOrders(account) {
        let data = await fetchData(api.url, api.apiKey, account, 'orders');
        if (data.orders) {
            setOrders(data.orders.sort( (a,b) => Number(b.price) - Number(a.price)));
        } else {
            setOrders([]);
        }
    }

    async function getTrades(account) {
        let data = await fetchData(api.url, api.apiKey, account, 'trades');
        if (data.trades) {
            setTrades(data.trades.sort( (a,b) =>  Number(b.price) - Number(a.price)));
        } else {
            setTrades([]);
        }
    }

    async function cancelOrder(id){
        let data = await cancelOrderRequset(api.url, api.apiKey, account, id);
        refresh(account);
    }

    async function closeTrade(id){
        let data = await closeTradeRequset(api.url, api.apiKey, account, id);
        refresh(account);
    }

    return (
        <Container>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">Default API Key</InputGroup.Text>
                <Form.Select aria-label=">Default API Key" value={apiType} onChange={handleApiChange}>
                    <option value={"NONE"}>NONE</option>
                    <option value={"DEMO"}>Demo Api</option>
                    <option value={"LIVE"}>Live Api</option>
                </Form.Select>
            </InputGroup>
            <AccountsPicker accounts={accounts} accountIn={account} onChange={(acc) => refresh(acc)}/>
            <Button onClick={ () => refresh(account)}>Refresh Orders/Trades </Button>
                <div>
                    <label> Orders </label>
                </div>
                <Table size={"sm"} responsive striped hover>
                    <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">createTime</th>
                        <th scope="col">Instrument</th>
                        <th scope="col">Price</th>
                        <th scope="col">Type</th>
                        <th scope="col">Units</th>
                        <th scope="col">State</th>
                        <th scope="col">Time In Force</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map((order) => (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{Moment(order.createTime).format('hh:mm:ss, MMM DD ')}</td>
                            <td>{order.instrument}</td>
                            <td>{order.price}</td>
                            <td>{order.type}</td>
                            <td>{order.units}</td>
                            <td>{order.state}</td>
                            <td>{order.timeInForce}</td>
                            <td><Button size={"sm"} variant={"danger"} onClick={ () => cancelOrder(order.id) }>Cancel</Button></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <div>
                    <label> Trades </label>
                </div>
                <Table size={"sm"} responsive striped hover >
                    <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">currentUnits</th>
                        <th scope="col">instrument</th>
                        <th scope="col">price</th>
                        <th scope="col">realizedPL</th>
                        <th scope="col">unrealizedPL</th>
                        <th scope="col">state</th>
                        <th scope="col">openTime</th>
                        <th scope="col">close</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trades.map( (trade) => (
                        <tr key={trade.id}>
                            <td>{trade.id}</td>
                            <td>{trade.currentUnits}</td>
                            <td>{trade.instrument}</td>
                            <td>{trade.price}</td>
                            <td>{trade.realizedPL}</td>
                            <td>{trade.unrealizedPL}</td>
                            <td>{trade.state}</td>
                            <td>{Moment(trade.openTime).format('hh:mm:ss, MMM DD')}</td>
                            <td><Button size={"sm"} variant={"danger"} onClick={ () => closeTrade(trade.id)}>Cancel</Button></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
        </Container>
    );
}

export default OrdersAndTradesView;
