import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import {Form, InputGroup} from "react-bootstrap";

export default memo(({ data, isConnectable }) => {
    const id = 'start';
    const handleConnect = (params) => {
        const { source, target, data } = params;
        const newData = { ...data, id: id };
        const connectionData = { source, target, data: newData };
        console.log('Editor: Sending data:', connectionData);
    };
    return (
        <>
            <div>
                Start
            </div>
            <Handle
                type="source"
                id="default"
                position={Position.Right}
                style={{ background: '#555' }}
                onConnect={handleConnect}
                isConnectable={isConnectable}
            />
        </>
    );
});