import 'bootstrap/dist/css/bootstrap.css';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Container, FloatingLabel, Row} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {BsFillCartCheckFill} from "react-icons/bs";
import {FaRunning} from "react-icons/fa";
import {AiFillRobot, AiOutlineRobot} from "react-icons/ai";
import {FcMoneyTransfer} from "react-icons/fc";
import BotOctopusServer from "./BotOctopusServer";
import {selectServerBotState} from "./serverBotStateSclicer";

function ServerBotsView() {
    const bots = ['bot1', 'bot2', 'bot3', 'bot4', 'bot5', 'bot6'];
    const botsState = useSelector(selectServerBotState);
    const dispatcher = useDispatch();
    const [countDown, setCountDown] = useState(
        Date.now()
    );
    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(Date.now());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Container fluid>
            <Accordion alwaysOpen>
                {bots.map((bot, index) => (
                        <Row key={bot}>
                            <Accordion.Item key={bot} eventKey={bot} as={Col} lg={12}>
                                <Accordion.Header>{botsState[bot].config.apiType} {botsState[bot].run ? <AiFillRobot/> :
                                    <AiOutlineRobot/>}
                                    {botsState[bot].run && <>
                                        -> <FaRunning></FaRunning> {Math.round((countDown - botsState[bot].heartbeat) / 1000) + "sec ago"} ->
                                        {botsState[bot].config.instrument}({botsState[bot].config.orderType}) ->
                                        <BsFillCartCheckFill/>{botsState[bot].stats.c}<FcMoneyTransfer></FcMoneyTransfer> {parseFloat(botsState[bot].stats.p).toFixed(2)} {"(profit)"} {parseFloat(botsState[bot].stats.un).toFixed(2)} {"(unrealizedPL)"} {botsState[bot].stats.o} {"(orders)"}
                                        {botsState[bot].config.autoStopDollarAmount > 0 &&
                                            <>
                                                <br/>{botsState[bot].config.autoStopDollarAmount}{"$(autostop) "}<b>{ (parseFloat(botsState[bot].stats.p) + parseFloat(botsState[bot].stats.un)).toFixed(2)}{"$(Profit/Loss Live)"}</b>
                                            </>
                                        }
                                    </>
                                    }</Accordion.Header>
                                <Accordion.Body>
                                    {botsState[bot].prevRunStats && <>
                                        Previous run State ->
                                        <BsFillCartCheckFill/>{botsState[bot].prevRunStats.c}<FcMoneyTransfer></FcMoneyTransfer> {parseFloat(botsState[bot].prevRunStats.p).toFixed(2)} {"(profit)"} {parseFloat(botsState[bot].prevRunStats.un).toFixed(2)} {"(unrealizedPL)"} {botsState[bot].prevRunStats.o} {"(orders) "}
                                        <b>{ (parseFloat(botsState[bot].prevRunStats.p) + parseFloat(botsState[bot].prevRunStats.un)).toFixed(2)}{"$(Profit/Loss)"}</b>
                                    </>
                                    }
                                    <BotOctopusServer botId={bot}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Row>
                    )
                )}
            </Accordion>
        </Container>
    )
}

export default ServerBotsView;
