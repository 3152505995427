import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    MiniMap,
    NodeResizer,
    NodeToolbar,
    Panel,
    useEdgesState,
    useNodesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import {Button, InputGroup, Form} from "react-bootstrap";
import OrderNode from "./OrderNode";
import _ from "lodash";
import AnalyticsNode from "./AnalyticsNode";
import StartNode from "./StartNode";
import {useDispatch, useSelector} from "react-redux";
import {selectLuckyStrategyState, setLuckyStrategy} from "../luckyStrategy/luckyStrategyStateSclicer";
import {saveLuckyStrategyAsync} from "../datebase/db";
import {useLocation} from "react-router";

const initialEdges = [];
const nodeTypes = {
    orderNode: OrderNode,
    analyticsNode: AnalyticsNode,
    startNode: StartNode,
};

const buttonToolStyle = {
    margin: 5
};
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function BotEditor({strategyId}) {
    const dispatcher = useDispatch();
    const query = useQuery();
    const strategyFromQuery = query.get("strategyId");
    const strId = strategyId === undefined ? strategyFromQuery : strategyId;
    const strategyState = useSelector(selectLuckyStrategyState)[strId];
    const getNodes = (config) => {
        if (config === undefined) return [];
        if (config.nodes === undefined) return [];
        console.log("Nodes: " + JSON.stringify(config.nodes));
        return [...config.nodes];
    }
    const getEdges = (config) => {
        if (config === undefined) return [];
        if (config.edges === undefined) return [];
        console.log("Edges: " + JSON.stringify(config.edges));
        return [...config.edges];
    }

    const [id, setId] = useState(100);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const reloadNodeEdgeState = () => {
        setNodes(getNodes(strategyState.config));
        setEdges(getEdges(strategyState.config));
    }


    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    const saveCurrentState = () => {
        console.log("Editor: " + JSON.stringify({edges: edges,
                                          nodes: nodes}));
        let nds = JSON.parse(JSON.stringify(nodes));
        let edgs = JSON.parse(JSON.stringify(edges));
        let config = strategyState.config;
        let botSteps = JSON.parse(JSON.stringify(buildSteps(nds, edgs)));
        console.log("Steps: " + JSON.stringify(botSteps));
        dispatcher(setLuckyStrategy({index: strId, data: {...strategyState, botSteps: botSteps, config: {...config, nodes: nds, edges: edgs}}}));
        saveLuckyStrategyAsync(strId)
    }

    const buildSteps = (nodes, edges) => {
        let startPoints = [];
             //find all start id's
        let starts = _.filter(edges, obj => _.startsWith(obj.source, 'start'));
        for ( let next of starts) {
            startPoints.push(buildStep(nodes, edges, next.source, next.sourceHandle, next.targetHandle))
        }
        return startPoints;
    }

    const buildStep = (nodes, edges, id, event, hadler) => {
        //entry point to build object
        let children = [];
        let node = nodes.find(obj => obj.id === id);
        if (node === undefined){
            return {}
        }
        let config = node.data;

        let chld = _.filter(edges, obj => obj.source === id);
        for ( let next of chld) {
            children.push(buildStep(nodes, edges, next.target, next.sourceHandle, next.targetHandle))
        }

        return {
            id: node.id,
            type: node.type,
            event: event,
            handler: hadler,
            config: config,
            children: children
        }
    }

    const setStrategyName = (name) => {
        dispatcher(setLuckyStrategy({index: strId, data: {...strategyState, name: name}}));
    }

    const onDataChange = (id, data) => {
        let node = _.find(nodes, (item) => item.id === id);
        node.data = {...node.data, ...data};
        setNodes([...nodes]);
    };

    const addOrder = () => {
        addNode({
            id: 'order',
            type: 'orderNode',
            position: {x: 0, y: 0},
            style: {border: '1px solid #777', padding: 10, borderRadius: 10},
            data: {orderType: "LONG"},
            onDataChange: (id, data) => onDataChange(id, data),
            isConnectable: true
        });
    }

    const addNode = (node) => {
        node.id = node.id + (id + 1);
        let maxX = _.maxBy(nodes, (item) => item.position.x)?.position.x || 0;
        node.id = node.id + (id + 1);
        node.position.x = maxX + 100;
        nodes.push(node);
        setNodes([...nodes]);
        setId(id + 1);
    }
    const addStatNode = () => {
        addNode({
            id: 'start',
            type: 'startNode',
            position: {x: 0, y: 0},
            style: {border: '1px solid #777', padding: 10, borderRadius: 10},
            onDataChange: onDataChange,
            isConnectable: true,
        })
    }

    const addAnalyticsNode = () => {
        addNode({
            id: 'analytics',
            type: 'analyticsNode',
            position: {x: 0, y: 0},
            style: {border: '1px solid #777', padding: 10, borderRadius: 10},
            onDataChange: onDataChange,
            isConnectable: true,
        })
    }


    return (
        <div style={{width: '100vw', height: '100vh'}}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
            >
                <Panel position={"top-left"}>
                    <Button style={buttonToolStyle} variant={'success'} onClick={() => reloadNodeEdgeState()}>Reload</Button>
                    <Button style={buttonToolStyle} variant={'success'} onClick={() => saveCurrentState()}>Save</Button>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">Name: </InputGroup.Text>
                        <Form.Control
                            placeholder="Strategy name"
                            aria-label="Strategy name"
                            aria-describedby="basic-addon1"
                            value={strategyState.name}
                            onChange={(e) => { setStrategyName(e.target.value) } }
                        />
                    </InputGroup>
                    <Button style={buttonToolStyle} variant={'secondary'} onClick={() => addStatNode()}>Start Node</Button>
                    <Button style={buttonToolStyle} variant={'secondary'} onClick={() => addAnalyticsNode()}>Analytics Node</Button>
                    <Button style={buttonToolStyle} variant={'secondary'} onClick={() => addOrder()}>Order Node</Button>
                </Panel>
                <Controls/>
                <MiniMap/>
                <NodeToolbar/>
                <NodeResizer/>
                <Background variant="dots" gap={12} size={1}/>
            </ReactFlow>
        </div>
    );
}

export default BotEditor;