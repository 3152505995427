import React from 'react';
import {
    selectUserSettingsApiKey,
    selectUserSettingsUrl,
    setUserSettingsApiKey,
    setUserSettingsAccounts,
    setUserSettingsUrl,
    selectUserSettingsLiveApiKey,
    selectUserSettingsDemoApiKey,
    setUserSettingsDemoApiKey,
    setUserSettingsDemoAccounts,
    setUserSettingsLiveAccounts,
    selectUserSettingsDemoAccounts,
    selectUserSettingsLiveAccounts,
    setUserSettingsLiveApiKey
} from "./userSettingsSlicer";
import {useDispatch, useSelector} from "react-redux";
import {saveUserSettingsAsync} from "../datebase/db";
import {Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import AccountsPicker from "./AccountsPicker";

function UserSettings() {
    const apiKey = useSelector(selectUserSettingsApiKey);
    const demoApiKey = useSelector(selectUserSettingsDemoApiKey);
    const liveApiKey = useSelector(selectUserSettingsLiveApiKey);
    const demoAccounts = useSelector(selectUserSettingsDemoAccounts);
    const liveAccounts = useSelector(selectUserSettingsLiveAccounts);
    const dispatch = useDispatch();
    const url = useSelector(selectUserSettingsUrl);
    const defaultKey = apiKey === liveApiKey ? "LIVE" : (apiKey === demoApiKey ? "DEMO" : "NONE") ;

    const handleDefaultApiChange = (event) => {
        let keys = { DEMO: {apiKey: demoApiKey, url: "api-fxpractice.oanda.com", accounts: demoAccounts},
                     LIVE: {apiKey: liveApiKey, url: "api-fxtrade.oanda.com", accounts: liveAccounts},
                     NONE: {apiKey: demoApiKey, url: "api-fxpractice.oanda.com", accounts: demoAccounts}}
        let value = keys[event.target.value];
        dispatch(setUserSettingsApiKey(value.apiKey));
        dispatch(setUserSettingsUrl(value.url));
        dispatch(setUserSettingsAccounts(value.accounts));
        saveUserSettingsAsync();
    };
    const handleDemoApiChange = (event) => {
        dispatch(setUserSettingsDemoApiKey(event.target.value));
        saveUserSettingsAsync();
    };
    const handleLiveApiChange = (event) => {
        dispatch(setUserSettingsLiveApiKey(event.target.value));
        saveUserSettingsAsync();
    };

    const setAccountsDemo = (accounts) => {
        dispatch(setUserSettingsDemoAccounts(accounts));
        saveUserSettingsAsync();
    };

    const setLiveAccounts = (accounts) => {
        dispatch(setUserSettingsLiveAccounts(accounts));
        saveUserSettingsAsync();
    };

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Container fluid>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon2">Demo API Key</InputGroup.Text>
                        <Form.Control value={demoApiKey}
                                      onChange={handleDemoApiChange}
                                      type="text"
                                      aria-describedby="basic-addon2"
                                      placeholder="Enter your Oanda API KEY Demo"/>
                    </InputGroup>
                    <AccountsPicker apiKey={demoApiKey}
                                    url={"api-fxpractice.oanda.com"} enableLoad={true}
                                    accounts={demoAccounts ? demoAccounts : []}
                                    onLoad={(accounts) => setAccountsDemo(accounts)}/>
                    <InputGroup className="mb-3">
                        <InputGroup.Text variant={"success"} id="basic-addon1" >Live API Key</InputGroup.Text>
                        <Form.Control value={liveApiKey}
                                      onChange={handleLiveApiChange}
                                      type="text"
                                      aria-describedby="basic-addon1"
                                      placeholder="Enter your Oanda API KEY Live"/>
                    </InputGroup>
                    <AccountsPicker apiKey={liveApiKey} url={"api-fxtrade.oanda.com"} enableLoad={true}
                                    accounts={liveAccounts}
                                    onLoad={(accounts) => setLiveAccounts(accounts)}/>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Default API Key</InputGroup.Text>
                        <Form.Select aria-label=">Default API Key" value={defaultKey} onChange={handleDefaultApiChange}>
                            <option value={"NONE"}>NONE</option>
                            <option value={"DEMO"}>Demo Api</option>
                            <option value={"LIVE"}>Live Api</option>
                        </Form.Select>
                    </InputGroup>

                </Container>
            </Form.Group>
        </Form>
    );
}

export default UserSettings;
