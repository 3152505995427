import {configureStore} from '@reduxjs/toolkit'
import loginReducer from "./login/loginSlicer";
import userSettingsReducer from "./user/userSettingsSlicer";
import botStateReducer from "./bot/botStateSclicer";
import serverBotStateReducer from "./serverBot/serverBotStateSclicer";
import snakeBotStateReducer from "./snakeBot/snakeBotStateSclicer";
import luckyBotStateReducer from "./luckyBot/luckyBotStateSclicer"
import luckyStrategyStateReducer from "./luckyStrategy/luckyStrategyStateSclicer"

export default configureStore({
    reducer: {
        login: loginReducer,
        userSettings: userSettingsReducer,
        botState: botStateReducer,
        serverBotState: serverBotStateReducer,
        snakeBotState: snakeBotStateReducer,
        luckyBotState: luckyBotStateReducer,
        luckyStrategyState: luckyStrategyStateReducer,
    }
})