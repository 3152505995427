import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {useSelector} from "react-redux";
import {selectLoginIsLoggedIn} from "./login/loginSlicer";
import BotEditor from "./editor/BotEditor";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./HomePage";

function App() {
    const isLoggedIn = useSelector(selectLoginIsLoggedIn);

    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<HomePage/>}/>
                {isLoggedIn &&
                    <Route path="/edit" element={<BotEditor/>}/>
                }
            </Routes>
        </Router>
    );
}

export default App;
