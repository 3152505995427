import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import {Form, InputGroup} from "react-bootstrap";

export default memo(({ data, isConnectable }) => {
    const analytics = {up: false, down: false};
    const handleConnect = (params) => {
        const { source, target, data } = params;
        const newData = { ...data, analytics: analytics };
        const connectionData = { source, target, data: newData };
        console.log('Editor: Sending data:', connectionData);
    };

    return (
        <>
            <div>
                Analytics
            </div>
            <Handle
                type="source"
                id = 'up'
                position={Position.Right}
                style={{  top: 10, background: '#555' }}
                onConnect={handleConnect}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                id = 'down'
                position={Position.Right}
                style={{  bottom: 10, top: 'auto', background: '#555' }}
                onConnect={handleConnect}
                isConnectable={isConnectable}
            />
            <Handle
                type="target"
                position="left"
                id="default"
                onConnect={handleConnect}
                style={{ background: '#555', top: '50%', transform: 'translateY(-50%)' }}
                isConnectable={isConnectable}
            />
        </>
    );
});