import React, {memo, useState} from 'react';
import {Handle, useReactFlow, useStoreApi} from 'reactflow';
import {Form, Row, Col} from 'react-bootstrap';

export default memo(({id, data, isConnectable, onDataChange}) => {
    const [state, setState] = useState({...data});
    const store = useStoreApi();
    const { setNodes } = useReactFlow();

    // const handleOrderTypeChange = (event) => {
    //     const selectedOrderType = event.target.value;
    //     state.orderType = selectedOrderType;
    //     setState({...state});
    //     onDataChange({...state});
    // };

    const handleOrderTypeChange = (event) => {
        const { nodeInternals } = store.getState();
        const selectedOrderType = event.target.value;
        state.orderType = selectedOrderType;

        setState({...state});
        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                if (node.id === id) {
                    node.data = {
                        ...node.data,
                        orderType: selectedOrderType,
                    };
                }
                return node;
            })
        );
    };

    const handleTakeProfitChange = (event) => {
        const value = event.target.value;
        state.takeProfit = value;
        setState({...state});
        onDataChange(id,state);
    };

    const handleStopLossChange = (event) => {
        const value = event.target.value;
        state.stopLoss = value;
        setState({...state});
        onDataChange(id,state);
    };

    const handleConnect = (params) => {
        // const { source, target, data: connectionData } = params;

        console.log('Editor: Received data:', params);

        // Handle the received data or perform further operations
    };


    return (
        <div>
            <div>
                <strong>Order Component</strong>
            </div>
            {/*<div>*/}
            {/*    <strong>Start Price:</strong> {state?.startPrice}*/}
            {/*</div>*/}

            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    Order Type:
                </Form.Label>
                <Col sm={10}>
                    <Form.Control as="select" value={state?.orderType} onChange={handleOrderTypeChange}>
                        <option value="LONG">LONG</option>
                        <option value="SHORT">SHORT</option>
                    </Form.Control>
                </Col>
            </Form.Group>

            {/*<Form.Group as={Row}>*/}
            {/*    <Form.Label column sm={2}>*/}
            {/*        Take Profit:*/}
            {/*    </Form.Label>*/}
            {/*    <Col sm={10}>*/}
            {/*        <Form.Control type="number" value={state?.takeProfit} onChange={handleTakeProfitChange} />*/}
            {/*    </Col>*/}
            {/*</Form.Group>*/}

            {/*<Form.Group as={Row}>*/}
            {/*    <Form.Label column sm={2}>*/}
            {/*        Stop Loss:*/}
            {/*    </Form.Label>*/}
            {/*    <Col sm={10}>*/}
            {/*        <Form.Control type="number" value={state?.stopLoss} onChange={handleStopLossChange} />*/}
            {/*    </Col>*/}
            {/*</Form.Group>*/}

            {/* Input Handle */}

            {/*<Handle*/}
            {/*    type="source"*/}
            {/*    position="right"*/}
            {/*    style={{ background: '#555', top: '50%', transform: 'translateY(-50%)' }}*/}
            {/*    isConnectable={isConnectable}*/}
            {/*/>*/}

            {/* Output Handle */}
            <Handle
                type="target"
                position="left"
                onConnect={handleConnect}
                style={{background: '#555', top: '50%', transform: 'translateY(-50%)'}}
                isConnectable={isConnectable}
            />

        </div>
    );
});
