import { createSlice } from '@reduxjs/toolkit'


const newDefaultBot = () => {
    return {
        name: "Strategy",
        config: {edges: [],
                 nodes: []}
    }
};

export const strategyIds = ["strategy1", "strategy2", "strategy3"];

const defaultSettings = { strategy1: newDefaultBot(),
                          strategy2: newDefaultBot(),
                          strategy3: newDefaultBot(),
}

export const luckyStrategyStateSlicer = createSlice({
    name: 'luckyStrategyState',
    initialState: {
        value: defaultSettings
    },
    reducers: {
        setLuckyStrategy: (state, action ) => {
            let id = action.payload.index;
            let data = action.payload.data;
            if (data === undefined || data === null) return;
            let botState = state.value[id];
            botState = data;
            state.value[id] = {...botState};
        }
    }
})

// Action creators are generated for each case reducer function
export const { setLuckyStrategy } = luckyStrategyStateSlicer.actions
export const selectLuckyStrategyState = (state) => state.luckyStrategyState.value
export default luckyStrategyStateSlicer.reducer