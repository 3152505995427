import 'bootstrap/dist/css/bootstrap.css';
import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLoginIsLoggedIn, selectLoginUser} from "./loginSlicer";
import {logIn, logOut} from "../firebaseConfig";
import {Button, Container,  FormLabel} from "react-bootstrap";

function Login() {
    const isLoggedIn = useSelector(selectLoginIsLoggedIn);
    const user = useSelector(selectLoginUser);

    return (
        <Container>
            {!isLoggedIn && <Button style={{fontSize: 14}} onClick={ () => logIn() }>{"Login"}</Button>}
            {isLoggedIn &&
                <FormLabel style={{fontSize: 14 }}>{user.displayName} <Button style={{fontSize: 14}} onClick={ () => logOut() }>{"Log out"}</Button>
                </FormLabel>}
        </Container>
    )
}

export default Login;
