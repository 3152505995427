import {getDatabase, ref, set, child, get, onValue} from "firebase/database";
import store from "../store";
import {setBotsFromDB} from "../bot/botStateSclicer";
import {
    setServerBotConfig,
    setServerBotHeartbeat,
    setServerBotRunState,
    setServerBotStats,
    setServerBotprevRunStats
} from "../serverBot/serverBotStateSclicer";
import {
    setSnakeBotConfig,
    setSnakeBotHeartbeat,
    setSnakeBotRunState,
    setSnakeBotStats
} from "../snakeBot/snakeBotStateSclicer";
import {
    setLuckyBotConfig,
    setLuckyBotHeartbeat,
    setLuckyBotRunState,
    setLuckyBotStats
} from "../luckyBot/luckyBotStateSclicer";
import {setLuckyStrategy} from "../luckyStrategy/luckyStrategyStateSclicer";

const SERVER_BOT_PATH = 'serverBots/';
const SNAKE_BOT_PATH = 'snakeBot/';
const LUCKY_BOT_PATH = 'luckyBot/';
const LUCKY_BOT_STRATEGY_PATH = 'luckyBotStrategy/';
export const saveUserSettingsAsync = () => {
    setTimeout(() => {
        let userSettings = store.getState().userSettings.value;
        let userId = store.getState().login.value.user.uid;
        saveUserSettings(userId, userSettings);
    });
}

export const saveUserBotsAsync = () => {
    setTimeout(() => {
        let bots = store.getState().botState.value;
        let userId = store.getState().login.value.user.uid;
        saveBots(userId, bots);
    });
}

export const listenOnBotsChange = (userId) => {
    const db = getDatabase();
    const starCountRef = ref(db, 'bots/' + userId);
    onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setBotsFromDB(data));
        console.log(data);
    });
}

export const listenOnServerBotChange = (bot, userId, botId) => {
    const db = getDatabase();
    const starCountRef = ref(db, bot.path + "/" + userId + '/' + botId + "/config");
    onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setServerBotConfig({index: botId, config: data}));
        console.log(data);
    });
    const starStatsRef = ref(db, bot.path + "/" + userId + '/' + botId + "/stats");
    onValue(starStatsRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setServerBotStats({index: botId, stats: data}));
        console.log(data);
    });
    const starHeartbeatRef = ref(db, bot.path + "/" + userId + '/' + botId + "/heartbeat");
    onValue(starHeartbeatRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setServerBotHeartbeat({index: botId, heartbeat: data}));
        console.log(data);
    });
    const starRunRef = ref(db, bot.path + "/" + userId + '/' + botId + "/run");
    onValue(starRunRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setServerBotRunState({index: botId, run: data}));
        console.log(data);
    });

    const starprevRunStatsRef = ref(db, bot.path + "/" + userId + '/' + botId + "/prevRunStats");
    onValue(starprevRunStatsRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setServerBotprevRunStats({index: botId, prevRunStats: data}));
        console.log(data);
    });
}

export const listenOnLuckyStrategyChange = (bot, userId, id) => {
    const db = getDatabase();
    const starCountRef = ref(db, bot.path + "/" + userId + '/' + id);
    onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setLuckyStrategy({index: id, data: data}));
        console.log(data);
    });
}
export const listenOnLuckyBotChange = (bot, userId, botId) => {
    const db = getDatabase();
    const starCountRef = ref(db, bot.path + "/" + userId + '/' + botId + "/config");
    onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setLuckyBotConfig({index: botId, config: data}));
        console.log(data);
    });
    const starStatsRef = ref(db, bot.path + "/" + userId + '/' + botId + "/stats");
    onValue(starStatsRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setLuckyBotStats({index: botId, stats: data}));
        console.log(data);
    });
    const starHeartbeatRef = ref(db, bot.path + "/" + userId + '/' + botId + "/heartbeat");
    onValue(starHeartbeatRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setLuckyBotHeartbeat({index: botId, heartbeat: data}));
        console.log(data);
    });
    const starRunRef = ref(db, bot.path + "/" + userId + '/' + botId + "/run");
    onValue(starRunRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setLuckyBotRunState({index: botId, run: data}));
        console.log(data);
    });
}

export const listenOnSnakeBotChange = (bot, userId, botId) => {
    const db = getDatabase();
    const starCountRef = ref(db, bot.path + "/" + userId + '/' + botId + "/config");
    onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setSnakeBotConfig({index: botId, config: data}));
        console.log(data);
    });
    const starStatsRef = ref(db, bot.path + "/" + userId + '/' + botId + "/stats");
    onValue(starStatsRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setSnakeBotStats({index: botId, stats: data}));
        console.log(data);
    });
    const starHeartbeatRef = ref(db, bot.path + "/" + userId + '/' + botId + "/heartbeat");
    onValue(starHeartbeatRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setSnakeBotHeartbeat({index: botId, heartbeat: data}));
        console.log(data);
    });
    const starRunRef = ref(db, bot.path + "/" + userId + '/' + botId + "/run");
    onValue(starRunRef, (snapshot) => {
        const data = snapshot.val();
        store.dispatch(setSnakeBotRunState({index: botId, run: data}));
        console.log(data);
    });
}

export const saveUserSettings = (userId, settings) => {
    const db = getDatabase();
    set(ref(db, 'users/' + userId), settings);
};

export const saveBots = (userId, settings) => {
    const db = getDatabase();
    set(ref(db, 'bots/' + userId), settings);
};

export const saveServerBotsConfigAsync = (botId) => {
    setTimeout(() => {
        let botConfig = store.getState().serverBotState.value[botId].config;
        let userId = store.getState().login.value.user.uid;
        saveBotConfig(SERVER_BOT_PATH, userId, botId, botConfig);
    });
}

export const saveLuckyBotsConfigAsync = (botId) => {
    setTimeout(() => {
        let botConfig = store.getState().luckyBotState.value[botId].config;
        let userId = store.getState().login.value.user.uid;
        saveBotConfig(LUCKY_BOT_PATH, userId, botId, botConfig);
    });
}

export const saveSnakeBotConfigAsync = (botId) => {
    setTimeout(() => {
        let botConfig = store.getState().snakeBotState.value[botId].config;
        let userId = store.getState().login.value.user.uid;
        saveBotConfig(SNAKE_BOT_PATH, userId, botId, botConfig);
    });
}
export const saveServerBotsRunAsync = (botId) => {
    setTimeout(() => {
        let botRunState = store.getState().serverBotState.value[botId].run;
        let userId = store.getState().login.value.user.uid;
        saveBotRun(SERVER_BOT_PATH, userId, botId, botRunState);
    });
}
export const saveLuckyBotsRunAsync = (botId) => {
    setTimeout(() => {
        let botRunState = store.getState().luckyBotState.value[botId].run;
        let userId = store.getState().login.value.user.uid;
        saveBotRun(LUCKY_BOT_PATH, userId, botId, botRunState);
    });
}

export const saveLuckyStrategyAsync = (id) => {
    setTimeout(() => {
        let state = store.getState().luckyStrategyState.value[id];
        let userId = store.getState().login.value.user.uid;
        saveState(LUCKY_BOT_STRATEGY_PATH, userId, id, state);
    });
}

export const saveSnakeBotRunAsync = (botId) => {
    setTimeout(() => {
        let botRunState = store.getState().snakeBotState.value[botId].run;
        let userId = store.getState().login.value.user.uid;
        saveBotRun(SNAKE_BOT_PATH, userId, botId, botRunState);
    });
}
export const saveBotRun = (path, userId, botId, botRunState) => {
    const db = getDatabase();
    set(ref(db, path + userId + '/' + botId + '/run'), botRunState);
};

export const saveBotConfig = (path, userId, botId, settings) => {
    const db = getDatabase();
    set(ref(db, path + userId + '/' + botId + '/config'), settings);
};

export const saveState = (path, userId, id, state) => {
    const db = getDatabase();
    set(ref(db, path + userId + '/' + id), state);
};

export const getUserBots = (userId, callback) => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `bots/${userId}`)).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            callback(snapshot.val());
        } else {
            console.log("No data available");
        }
    }).catch((error) => {
        console.error(error);
    });
};
export const getUserSettings = (userId, callback) => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `users/${userId}`)).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            callback(snapshot.val());
        } else {
            console.log("No data available");
        }
    }).catch((error) => {
        console.error(error);
    });
};