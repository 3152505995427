import { createSlice } from '@reduxjs/toolkit'
import uuid from "react-uuid";

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        value: {isLoggedIn: false,
                user: {},
                device: {},
                sessionUuid: uuid()}
    },
    reducers: {
        setUser: (state, action) => {
            let user = {uid: action.payload.uid, displayName: action.payload.displayName, email: action.payload.email}
            state.value = {...state.value, user: user}
        },
        setDevice: (state, action) => {
            let device = action.payload;
            state.value = {...state.value, device: device}
        },
        updateDeviceHeartbeat: (state) => {
            let device = {...state.value.device, heartbeat: Date.now()};
            state.value = {...state.value, device: device}
        },
        isLoggedIn: (state, action) => {
            state.value = {...state.value, isLoggedIn: action.payload}
        }
    }
})

// Action creators are generated for each case reducer function
export const { setUser, isLoggedIn, setDevice, updateDeviceHeartbeat} = loginSlice.actions
export const selectLoginUser = (state) => state.login.value.user
export const selectLoginIsLoggedIn = (state) => state.login.value.isLoggedIn
export const selectLoginDeviceId = (state) => state.login.value.deviceId
export const selectLoginSessionUuid = (state) => state.login.value.sessionUuid

export default loginSlice.reducer